<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
      :show-one-children="true"
    >
      <template slot-scope="props" slot="links">
        <template v-for="(items, i) in sidebarData">
          <div :key="i" v-if="roles.includes(items.user)">
            <template v-for="(item, index) in items.data">
              <li
                :key="index"
                class="sidebar-menu-item"
                @click="toggleAccordion(index, $event)"
                ref="sidebarMenuItem"
              >
                <div
                  v-if="item && item.children && item.children.length !== 0"
                  class="main-menu-item"
                  :class="
                    isClickedParentItem === index ? 'clicked-menu-item' : ''
                  "
                >
                  <template v-if="item.name === 'loginuser'">
                    <img :src="profileImage" class="user-image" />
                    <p
                      class="parent-item"
                      :class="
                        $store.getters['sidebarToggle/getToggleKey']
                          ? 'parent-collapsed-item'
                          : ''
                      "
                    >
                      {{ title }}<b class="caret"></b>
                    </p>
                  </template>
                  <template v-else>
                    <i :class="item.icon"></i>
                    <p
                      class="parent-item"
                      :class="
                        $store.getters['sidebarToggle/getToggleKey']
                          ? 'parent-collapsed-item'
                          : ''
                      "
                    >
                      {{ $t(item.name) }}<b class="caret"></b>
                    </p>
                  </template>
                </div>
                <div
                  v-else
                  class="main-menu-item"
                  :class="
                    isClickedParentItem === index
                      ? 'clicked-child-menu-item'
                      : ''
                  "
                >
                  <router-link :to="item.path">
                    <i :class="item.icon"></i>
                    <p
                      class="parent-item"
                      :class="
                        $store.getters['sidebarToggle/getToggleKey']
                          ? 'parent-collapsed-item'
                          : ''
                      "
                    >
                      {{ $t(item.name) }}
                    </p>
                  </router-link>
                </div>
                <collapse-transition>
                  <div v-if="activeAccordion === index">
                    <template v-for="(childItem, childIndex) in item.children">
                      <div
                        :key="childIndex"
                        @click.stop
                        @click="handleClickedChildItem(childItem, childIndex)"
                      >
                        <a
                          v-if="childItem.path === '/logout'"
                          @click.prevent="logoutButton"
                          class="sidebar-normal"
                          :class="
                            childItem.path === isClickedChildItem
                              ? 'clicked-child-menu-item'
                              : ''
                          "
                        >
                          <span
                            class="sidebar-mini-icon"
                            :class="
                              $store.getters['sidebarToggle/getToggleKey']
                                ? 'child-mini-icon'
                                : ''
                            "
                            >{{ getInitials($t(childItem.name)) }}</span
                          >
                          <span
                            class="mini-item"
                            :class="
                              $store.getters['sidebarToggle/getToggleKey']
                                ? 'child-collapsed-item'
                                : ''
                            "
                            >{{ $t(childItem.name) }}</span
                          >
                        </a>
                        <router-link
                          v-else
                          :to="childItem.path"
                          class="sidebar-normal"
                          :class="
                            childItem.path === isClickedChildItem
                              ? 'clicked-child-menu-item'
                              : ''
                          "
                        >
                          <span
                            class="sidebar-mini-icon"
                            :class="
                              $store.getters['sidebarToggle/getToggleKey']
                                ? 'child-mini-icon'
                                : ''
                            "
                            >{{ getInitials($t(childItem.name)) }}</span
                          >
                          <span
                            class="mini-item"
                            :class="
                              $store.getters['sidebarToggle/getToggleKey']
                                ? 'child-collapsed-item'
                                : ''
                            "
                            >{{ $t(childItem.name) }}</span
                          >
                        </router-link>
                      </div>
                    </template>
                  </div>
                </collapse-transition>
              </li>
            </template>
          </div>
        </template>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share> -->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter">66</content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import SidebarShare from "./SidebarSharePlugin";

const imageURL = process.env.VUE_APP_CLEAN_URL;

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";
import { CollapseTransition } from "vue2-transitions";

import Vuex from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare,
    CollapseTransition,
  },
  data() {
    return {
      roles: [],
      sidebarBackground: "blue",
      activeAccordion: null,
      isClickedParentItem: "",
      isClickedChildItem: window.location.pathname,
      avatar: null,
      title: null,
      sidebarData: [
        {
          user: "Manager",
          data: [
            {
              name: "loginuser",
              icon: "image",
              children: [
                { name: "sidebar.myProfile", path: "/profile" },
                { name: "sidebar.setting", path: "/settings" },
                { name: "sidebar.logout", path: "/logout" },
              ],
            },
            {
              name: "control panel",
              icon: "tim-icons icon-chart-pie-36",
              path: "/dashboard",
            },
            {
              name: "sidebar.content",
              icon: "far fa-file-video",
              children: [
                { name: "sidebar.cntLst", path: "/content/list-content" },
                { name: "sidebar.newCnt", path: "/content/new-content" },
                {
                  name: "sidebar.mltiNewCnt",
                  path: "/content/multiple-new-content",
                },
                { name: "sidebar.mdrtCnt", path: "/content/moderate-content" },
              ],
            },
            {
              name: "sidebar.playlist",
              icon: "fas fa-server",
              children: [
                { name: "sidebar.playlistList", path: "/playlist/play-list" },
                { name: "sidebar.newPlaylist", path: "/playlist/new-playlist" },
              ],
            },
            {
              name: "sidebar.container",
              icon: "fas fa-inbox",
              children: [
                {
                  name: "sidebar.containerList",
                  path: "/container/list-container",
                },
                {
                  name: "sidebar.newContainer",
                  path: "/container/new-container",
                },
                {
                  name: "sidebar.assPlayWithContainer",
                  path: "/container/associate-playlist",
                },
              ],
            },
            {
              name: "sidebar.template",
              icon: "fas fa-edit",
              children: [
                { name: "sidebar.tmpltList", path: "/template/list-template" },
                { name: "sidebar.designList", path: "/template/list-design" },
                { name: "sidebar.newDesign", path: "/template/new-design" },
                { name: "sidebar.newTemplate", path: "/template/new-template" },
              ],
            },
            {
              name: "sidebar.display",
              icon: "fas fa-desktop",
              children: [
                {
                  name: "sidebar.displList",
                  path: "/display/list-display-manager",
                },
                { name: "sidebar.newDisplay", path: "/display/new-display" },
              ],
            },
            {
              name: "sidebar.group",
              icon: "fas fa-sitemap",
              children: [
                { name: "sidebar.gpList", path: "/group/list-group-manager" },
                { name: "sidebar.newGrp", path: "/group/new-group-manager" },
                {
                  name: "sidebar.assignGrp",
                  path: "/group/assign-group-manager",
                },
                {
                  name: "sidebar.pendingGrp",
                  path: "/group/pending-action-manager",
                },
              ],
            },
            {
              name: "sidebar.user",
              icon: "fas fa-user",
              children: [
                {
                  name: "sidebar.newUser",
                  path: "/user-management/add-user-manager",
                },
                {
                  name: "sidebar.userList",
                  path: "/user-management/list-users-manager",
                },
              ],
            },
          ],
        },
        {
          user: "Administrator",
          data: [
            {
              name: "loginuser",
              icon: "image",
              children: [
                { name: "sidebar.myProfile", path: "/profile" },
                { name: "sidebar.setting", path: "/settings" },
                { name: "sidebar.logout", path: "/logout" },
              ],
            },
            {
              name: "control panel",
              icon: "tim-icons icon-chart-pie-36",
              path: "/dashboard",
            },
            {
              name: "sidebar.content",
              icon: "far fa-file-video",
              children: [
                { name: "sidebar.srtCntParam", path: "/content/list-content" },
              ],
            },
            {
              name: "sidebar.group",
              icon: "fas fa-sitemap",
              children: [
                { name: "sidebar.gpList", path: "/group/list-group" },
                { name: "sidebar.newGrp", path: "/group/new-group" },
                { name: "sidebar.sortGrp", path: "/group/sort-group" },
                { name: "sidebar.assignGrp", path: "/group/assign-group" },
                { name: "sidebar.pendingGrp", path: "/group/pending-action" },
              ],
            },
            {
              name: "sidebar.parameter",
              icon: "fas fa-cogs",
              children: [
                { name: "sidebar.newParam", path: "/paramlists/new-parameter" },
                {
                  name: "sidebar.paramList",
                  path: "/paramlists/research-params",
                },
                {
                  name: "sidebar.assignParam",
                  path: "/paramlists/assign_default_params",
                },
              ],
            },
            {
              name: "sidebar.template",
              icon: "fas fa-edit",
              children: [
                {
                  name: "sidebar.sortTemplate",
                  path: "/template/sort-template",
                },
              ],
            },
            {
              name: "sidebar.display",
              icon: "fas fa-desktop",
              children: [
                { name: "sidebar.displList", path: "/display/list-display" },
                { name: "sidebar.srtDispParam", path: "/display/sort-display" },
              ],
            },
            {
              name: "sidebar.user",
              icon: "fas fa-user",
              children: [
                { name: "sidebar.newUser", path: "/user-management/add-user" },
                {
                  name: "sidebar.userList",
                  path: "/user-management/list-users",
                },
                {
                  name: "sidebar.srtUserParam",
                  path: "/user-management/sort-user-param",
                },
              ],
            },
            {
              name: "sidebar.license",
              icon: "fas fa-cogs",
              children: [
                { name: "sidebar.newLicense", path: "/license/new-license" },
                // {
                //   name: "sidebar.addSubLicense",
                //   path: "/license/add-sub-license",
                // },
                { name: "sidebar.licenseList", path: "/license/list-license" },
                {
                  name: "sidebar.licenseExp",
                  path: "/license/license-expiration",
                },
              ],
            },
            {
              name: "sidebar.update",
              icon: "fas fa-server",
              children: [
                { name: "sidebar.excUpdate", path: "/update/excutable-update" },
                { name: "sidebar.pkgUpdate", path: "/update/package-update" },
              ],
            },
            {
              name: "sidebar.statistic",
              icon: "fas fa-server",
              children: [
                {
                  name: "sidebar.viewStatistic",
                  path: "/statistics/view-statics",
                },
                {
                  name: "sidebar.webcamStatistics",
                  path: "/statistics/web-cam",
                },
                { name: "sidebar.wifiStatistic", path: "/statistics/wi-fi" },
              ],
            },
          ],
        },
        {
          user: "Editor",
          data: [
            {
              name: "loginuser",
              icon: "image",
              children: [
                { name: "sidebar.myProfile", path: "/profile" },
                { name: "sidebar.setting", path: "/settings" },
                { name: "sidebar.logout", path: "/logout" },
              ],
            },
            {
              name: "control panel",
              icon: "tim-icons icon-chart-pie-36",
              path: "/dashboard",
            },
            {
              name: "sidebar.group",
              icon: "fas fa-sitemap",
              children: [
                { name: "sidebar.gpList", path: "/group/list-group-editor" },
              ],
            },
            {
              name: "sidebar.content",
              icon: "far fa-file-video",
              children: [
                { name: "sidebar.cntLst", path: "/content/list-content" },
                { name: "sidebar.newCnt", path: "/content/new-content" },
                {
                  name: "sidebar.mltiNewCnt",
                  path: "/content/multiple-new-content",
                },
                { name: "sidebar.mdrtCnt", path: "/content/moderate-content" },
              ],
            },
            {
              name: "sidebar.playlist",
              icon: "fas fa-server",
              children: [
                { name: "sidebar.playlistList", path: "/playlist/play-list" },
                { name: "sidebar.newPlaylist", path: "/playlist/new-playlist" },
              ],
            },
            {
              name: "sidebar.container",
              icon: "fas fa-inbox",
              children: [
                {
                  name: "sidebar.containerList",
                  path: "/container/list-container",
                },
                {
                  name: "sidebar.assPlayWithContainer",
                  path: "/container/associate-playlist",
                },
              ],
            },
            {
              name: "sidebar.display",
              icon: "fas fa-desktop",
              children: [
                {
                  name: "sidebar.displList",
                  path: "/display/list-display-other",
                },
              ],
            },
          ],
        },
        {
          user: "Author",
          data: [
            {
              name: "loginuser",
              icon: "image",
              children: [
                { name: "sidebar.myProfile", path: "/profile" },
                { name: "sidebar.setting", path: "/settings" },
                { name: "sidebar.logout", path: "/logout" },
              ],
            },
            {
              name: "control panel",
              icon: "tim-icons icon-chart-pie-36",
              path: "/dashboard",
            },
            {
              name: "sidebar.content",
              icon: "far fa-file-video",
              children: [
                { name: "sidebar.cntLst", path: "/content/list-content" },
                { name: "sidebar.newCnt", path: "/content/new-content" },
                {
                  name: "sidebar.mltiNewCnt",
                  path: "/content/multiple-new-content",
                },
              ],
            },
            {
              name: "sidebar.playlist",
              icon: "fas fa-server",
              children: [
                { name: "sidebar.playlistList", path: "/playlist/play-list" },
              ],
            },
            // {
            //   name: "sidebar.display",
            //   icon: "fas fa-desktop",
            //   children: [
            //     {
            //       name: "sidebar.displList",
            //       path: "/display/list-display-author",
            //     },
            //   ],
            // },
            // {
            //   name: "sidebar.group",
            //   icon: "fas fa-sitemap",
            //   children: [
            //     { name: "sidebar.gpList", path: "/group/list-group-author" },
            //   ],
            // },
          ],
        },
        {
          user: "Designer",
          data: [
            {
              name: "loginuser",
              icon: "image",
              children: [
                { name: "sidebar.myProfile", path: "/profile" },
                { name: "sidebar.setting", path: "/settings" },
                { name: "sidebar.logout", path: "/logout" },
              ],
            },
            {
              name: "control panel",
              icon: "tim-icons icon-chart-pie-36",
              path: "/dashboard",
            },
            {
              name: "sidebar.group",
              icon: "fas fa-sitemap",
              children: [
                { name: "sidebar.gpList", path: "/group/list-group-designer" },
              ],
            },
            {
              name: "sidebar.content",
              icon: "far fa-file-video",
              children: [
                { name: "sidebar.cntLst", path: "/content/list-content" },
                { name: "sidebar.newCnt", path: "/content/new-content" },
                {
                  name: "sidebar.mltiNewCnt",
                  path: "/content/multiple-new-content",
                },
                { name: "sidebar.mdrtCnt", path: "/content/moderate-content" },
              ],
            },
            {
              name: "sidebar.playlist",
              icon: "fas fa-server",
              children: [
                { name: "sidebar.playlistList", path: "/playlist/play-list" },
                { name: "sidebar.newPlaylist", path: "/playlist/new-playlist" },
              ],
            },
            {
              name: "sidebar.container",
              icon: "fas fa-inbox",
              children: [
                {
                  name: "sidebar.containerList",
                  path: "/container/list-container",
                },
                {
                  name: "sidebar.newContainer",
                  path: "/container/new-container",
                },
                {
                  name: "sidebar.assPlayWithContainer",
                  path: "/container/associate-playlist",
                },
              ],
            },
            {
              name: "sidebar.template",
              icon: "fas fa-edit",
              children: [
                { name: "sidebar.tmpltList", path: "/template/list-template" },
                { name: "sidebar.designList", path: "/template/list-design" },
                { name: "sidebar.newDesign", path: "/template/new-design" },
                { name: "sidebar.newTemplate", path: "/template/new-template" },
                { name: "sidebar.impZip", path: "/template/import-template" },
              ],
            },
            {
              name: "sidebar.display",
              icon: "fas fa-desktop",
              children: [
                {
                  name: "sidebar.displList",
                  path: "/display/list-display-other",
                },
              ],
            },
          ],
        },
        {
          user: "Controller Template",
          data: [
            {
              name: "loginuser",
              icon: "image",
              children: [
                { name: "sidebar.myProfile", path: "/profile" },
                { name: "sidebar.setting", path: "/settings" },
                { name: "sidebar.logout", path: "/logout" },
              ],
            },
            {
              name: "control panel",
              icon: "tim-icons icon-chart-pie-36",
              path: "/dashboard",
            },
            {
              name: "sidebar.display",
              icon: "fas fa-desktop",
              children: [
                {
                  name: "sidebar.displList",
                  path: "/display/list-display-controller",
                },
              ],
            },
          ],
        },
        {
          user: "Watcher",
          data: [
            {
              name: "loginuser",
              icon: "image",
              children: [
                { name: "sidebar.myProfile", path: "/profile" },
                { name: "sidebar.setting", path: "/settings" },
                { name: "sidebar.logout", path: "/logout" },
              ],
            },
            {
              name: "control panel",
              icon: "tim-icons icon-chart-pie-36",
              path: "/dashboard",
            },
            {
              name: "sidebar.display",
              icon: "fas fa-desktop",
              children: [
                {
                  name: "sidebar.displList",
                  path: "/display/list-display-watcher",
                },
              ],
            },
          ],
        },
      ],
      sidebarMenuItemWidth: false,
      childIndexData: "",
    };
  },
  mounted() {
    this.initScrollbar();
    this.$store.dispatch("profile/me");
    const storedAccordionIndex = localStorage.getItem("activeAccordion");
    if (storedAccordionIndex !== null) {
      this.activeAccordion = parseInt(storedAccordionIndex);
      this.isClickedParentItem = this.activeAccordion;
    }
    this.isClickedChildItem = window.location.pathname;
    this.isActiveSidebar();
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
    profileImage() {
      const url = imageURL + "images/users/" + this.avatar;

      return this.avatar ? url : "/img/placeholder.jpg";
    },
  },
  async beforeMount() {
    localStorage.role_id = 0;
    this.$store.watch(
      () => this.$store.getters["profile/me"],
      (me) => {
        this.title = me.name;
        this.avatar = me.profile_image;
        localStorage.role_id = me.roles[0].id;
        localStorage.user_id = me.id;
      }
    );
    try {
      await this.$store.dispatch("profile/me");
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push("/login");
        localStorage.removeItem("vue-authenticate.vueauth_access_token");
        this.$store.commit("isAuthenticated", { isAuthenticated: false });
      }
    }
  },
  watch: {
    "$route.path": {
      handler(newPath, oldPath) {
        if (
          newPath === "/dashboard" ||
          newPath.includes("/display/list-display") ||
          newPath.includes("/group/list-group") ||
          newPath.includes("/license/list-license") ||
          newPath.includes("/user-management/list-users") ||
          newPath.includes("display/detail-display-admin/")
        ) {
          this.sidebarData &&
            this.sidebarData.map((newdata) => {
              if (this.roles && this.roles.includes(newdata.user)) {
                newdata.data.map((subData, i) => {
                  if (newPath.includes(this.$t(subData.name).toLowerCase())) {
                    this.isClickedParentItem = i;
                    this.activeAccordion = i;
                    localStorage.setItem("activeAccordion", i);
                    localStorage.setItem(
                      "childActiveAccordionPath",
                      JSON.stringify(newPath)
                    );
                  } else if (subData.path === "/dashboard") {
                    this.isClickedParentItem = i;
                    this.activeAccordion = i;
                    localStorage.setItem("activeAccordion", i);
                  }
                });
              }
            });
        }
        this.isClickedChildItem = newPath;
        this.childIndexData = "";
      },
      immediate: true,
    },
    me: {
      handler: function (val) {
        this.roles = val.roles.map((r) => r.name);
      },
      deep: true,
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
    isActiveSidebar() {
      return localStorage.getItem("sidebaractive");
    },
    getInitials(text) {
      const words = text.split(" ");
      const initials = words.map((word) => word.substring(0, 1).toUpperCase());
      return initials.join("");
    },
    toggleAccordion(index, event) {
      this.isClickedParentItem = index;
      if (event.target.classList.contains("collapse")) {
        return; // Clicked element is a child accordion, so do not change the activeAccordion state
      }

      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
      localStorage.setItem(
        "activeAccordion",
        this.activeAccordion && this.activeAccordion.toString()
      );
      this.isClickedChildItem = "";
      localStorage.removeItem("childActiveAccordionPath");
    },
    handleClickedChildItem(data, index) {
      if (data.path !== "/logout") {
        if (this.isClickedChildItem === this.childIndexData) {
          window.location.reload();
        }
      }
      this.childIndexData = data.path;
      this.isClickedChildItem = window.location.pathname;
      localStorage.setItem(
        "childActiveAccordionPath",
        data.path && JSON.stringify(data.path)
      );
    },
    toggleContrplPanel() {
      localStorage.removeItem("activeAccordion");
      localStorage.removeItem("childActiveAccordionPath");
    },
    logoutButton() {
      this.$router.push("/login");
      this.$store.dispatch("logout");
      localStorage.removeItem("activeAccordion");
      localStorage.removeItem("childActiveAccordionPath");
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.sidebar-wrapper {
  overflow-x: hidden !important;
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}

.sidebar-menu-item {
  cursor: pointer;
}

.main-menu-item {
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
}

.clicked-menu-item {
  background-color: #56565a;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.sidebar-menu-item,
.sidebar-normal {
  border-radius: 5px;
  font-weight: 400;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.7498rem;
  padding: 10px 8px;
  line-height: 1.625rem;
}

.parent-collapsed-item {
  opacity: 0;
}

.sidebar-wrapper:hover .parent-collapsed-item {
  opacity: 1;
}

.sidebar-normal {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1 1;
  gap: 10px;
}
.sidebar-mini-icon,
.sidebar-normal {
  color: #ffffff;
}

.clicked-child-menu-item {
  color: white;
  background-color: #146fe4;
}
a:hover {
  color: var(--primary);
}

.sidebar-mini-icon {
  font-weight: 400;
  text-align: center;
  width: 20%;
  opacity: 0;
}

.child-mini-icon {
  opacity: 1;
}
.sidebar-wrapper:hover .child-mini-icon {
  opacity: 0;
}

.mini-item {
  color: white;
  width: 85%;
}

.child-collapsed-item {
  opacity: 0;
  display: none;
  width: 0;
}

.sidebar-wrapper:hover .child-collapsed-item {
  opacity: 1;
  display: inline;
  width: 85%;
}

.sidebar-wrapper::-webkit-scrollbar {
  width: 5px;
}

.sidebar-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sidebar-wrapper::-webkit-scrollbar-thumb {
  background: #56565a;
  border: 3px solid var(--background-color);
  border-radius: 10px;
}

.user-image {
  font-size: 20px;
  float: left;
  margin-right: 12px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
}
</style>
