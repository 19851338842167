import DashboardLayout from "src/pages/Layout/DashboardLayout.vue";
import AuthLayout from "src/pages/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

// Calendar
const Calendar = () =>
  import(
    /* webpackChunkName: "extra" */ "src/pages/Calendar/CalendarRoute.vue"
  );
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ "src/pages/Charts.vue");

// Components pages
const Buttons = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/Buttons.vue"
  );
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/GridSystem.vue"
  );
const Panels = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/Panels.vue"
  );
const SweetAlert = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/SweetAlert.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/Notifications.vue"
  );
const Icons = () =>
  import(/* webpackChunkName: "components" */ "src/pages/Components/Icons.vue");
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/Typography.vue"
  );

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";
import admin_creator from "@/middleware/admin_creator";

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard.vue"
  );
import Widgets from "src/pages/Widgets.vue";
// import Thankyou from "src/pages/Thankyou.vue";

// Forms pages
const RegularForms = () => import("src/pages/Forms/RegularForms.vue");
const ExtendedForms = () => import("src/pages/Forms/ExtendedForms.vue");
const ValidationForms = () => import("src/pages/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Forms/Wizard.vue");

// Maps pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ "src/pages/Maps/GoogleMaps.vue");
const FullScreenMap = () =>
  import(/* webpackChunkName: "extra" */ "src/pages/Maps/FullScreenMap.vue");
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ "src/pages/Maps/VectorMaps.vue");

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/UserProfile.vue");
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Pricing.vue");
  const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/TimeLinePage.vue");
const Thankyou = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Thankyou.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Register.vue");
const RTL = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/RTLPage.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Lock.vue");
const PasswordReset = () =>
  import(
    /* webpackChunkName: "password" */ "src/pages/Pages/Password/Reset.vue"
  );
const PasswordEmail = () =>
  import(
    /* webpackChunkName: "password" */ "src/pages/Pages/Password/Email.vue"
  );

// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ "src/pages/Tables/RegularTables.vue");
const ExtendedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Tables/ExtendedTables.vue"
  );
const PaginatedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Tables/PaginatedTables.vue"
  );

// Example pages
const UserProfile = () => import("src/pages/Pages/Examples/CreateNewParam.vue");
// Role Management
// const ListRolePage = () => import("src/pages/Pages/Examples/role/research_params.vue");
const AddRolePage = () =>
  import("src/pages/Pages/Examples/role/AddRolePage.vue");
const EditRolePage = () =>
  import("src/pages/Pages/Examples/role/EditRolePage.vue");
// User Management
const ListUserPage = () => import("src/pages/UserManagement/ListUserPage.vue");
const ListUserManagerPage = () =>
  import("src/pages/UserManagement/ListUserManager.vue");
const AddUserPage = () => import("src/pages/UserManagement/AddUserPage.vue");
const AddUserManagerPage = () =>
  import("src/pages/UserManagement/AddUserManager.vue");
const EditUserPage = () => import("src/pages/UserManagement/EditUserPage.vue");
const EditUserManagerPage = () =>
  import("src/pages/UserManagement/EditUserManager.vue");
const SortUserParam = () =>
  import("src/pages/UserManagement/SortUserParameter.vue");
// Category Management
const ListCategoryPage = () =>
  import("src/pages/Pages/Examples/CategoryManagement/ListCategoryPage.vue");
const AddCategoryPage = () =>
  import("src/pages/Pages/Examples/CategoryManagement/AddCategoryPage.vue");
const EditCategoryPage = () =>
  import("src/pages/Pages/Examples/CategoryManagement/EditCategoryPage.vue");
// Paramlists Management
const ListTagPage = () => import("src/pages/paramlists/AssignParams.vue");
const AddTagPage = () => import("src/pages/paramlists/AddTagPage.vue");
const EditTagPage = () => import("src/pages/paramlists/EditTagPage.vue");
const NewParameter = () => import("src/pages/paramlists/NewParameter.vue");
const ListParameter = () => import("src/pages/paramlists/ResearchParams.vue");
// Item Management
const ListItemPage = () =>
  import("src/pages/Pages/Examples/log/user_action.vue");
const AddItemPage = () =>
  import("src/pages/Pages/Examples/log/AddItemPage.vue");
const EditItemPage = () =>
  import("src/pages/Pages/Examples/log/EditItemPage.vue");

// license Management
const NewLicense = () => import("src/pages//license/NewLicense.vue");
const ListLicense = () => import("src/pages/license/ListLicense.vue");
const LicenseExpiration = () =>
  import("src/pages/license/LicenseExpiration.vue");
// const LicenseDetail = () => import ("src/pages/license/LicenseDetail.vue");
const AddLicense = () => import("src/pages/license/AddLicense.vue");
const ChangeLicense = () => import("src/pages/license/ChangeLicense.vue");
const EditLicense = () => import("src/pages/license/EditLicense.vue");

// Statistics Management
const ViewStatics = () => import("src/pages/statistics/ViewStatics.vue");
const WebCam = () => import("src/pages/statistics/WebCam.vue");
const WiFi = () => import("src/pages/statistics/Wifi.vue");

// Display Management
const DisplayList = () => import("src/pages//display/DisplayList.vue");
const DisplayListManager = () =>
  import("src/pages//display/DisplayListManager.vue");
const DisplayListOther = () =>
  import("src/pages//display/DisplayListOther.vue");
const AssociateDisplay = () =>
  import("src/pages//display/AssociateDisplay.vue");
const NewDisplay = () => import("src/pages/display/NewDisplay.vue");
const DisplayTemplate = () => import("src/pages/display/DisplayTemplate.vue");
const EditDisplay = () => import("src/pages/display/EditDisplay.vue");
const EditDisplayManager = () =>
  import("src/pages/display/EditDisplayManager.vue");
const SortDisplay = () => import("src/pages/display/SortDisplay.vue");
const DetailDisplay = () => import("src/pages/display/DetailDisplay.vue");
const DetailDisplayOther = () =>
  import("src/pages/display/DetailDisplayOther.vue");
const DetailDisplayAdmin = () =>
  import("src/pages/display/DetailDisplayAdmin.vue");
const DetailDisplayDesigner = () =>
  import("src/pages/display/DisplayDetailsDesigner.vue");
const Displaylistauthor = () =>
  import("src/pages/display/DisplayListAuthor.vue");
const Displaylistcontroller = () =>
  import("src/pages/display/DisplayListController.vue");
const Displaylistwatcher = () =>
  import("src/pages/display/DisplayListWatcher.vue");
const Displaydetailauthor = () =>
  import("src/pages/display/DisplayDetailAuthor.vue");
const Displaydetailcontroller = () =>
  import("src/pages/display/DisplayDetailController.vue");
const Displaydetailwatcher = () =>
  import("src/pages/display/DisplayDetailWatcher.vue");

// Update Management
const ExcutableUpdate = () => import("src/pages/update/ExcutableUpdate.vue");
const PackageUpdate = () => import("src/pages/update/PackageUpdate.vue");

// Playlist Management
const Playlist = () => import("src/pages/playlist/PlayList.vue");
const NewPlaylist = () => import("src/pages/playlist/NewPlaylist.vue");
const EditPlaylist = () => import("src/pages/playlist/EditPlaylist.vue");

// Container Management
const ListContainer = () => import("src/pages/container/ContainerList.vue");
const EditContainer = () => import("src/pages/container/EditContainer.vue");
const NewContainer = () => import("src/pages/container/NewContainer.vue");
const AssociatPlaylist = () =>
  import("src/pages/container/AssociatPlaylist.vue");
const PlaylistAssociate = () =>
  import("src/pages/container/PlaylistAssociate.vue");

// Group Management
const GroupList = () => import("src/pages/group/GroupList.vue");
const NewGroup = () => import("src/pages/group/NewGroup.vue");
const AssignGroup = () => import("src/pages/group/AssignGroup.vue");
const AssignGroupManager = () =>
  import("src/pages/group/AssignGroupManager.vue");
const PendingAction = () => import("src/pages/group/PendingAction.vue");
const SortGroupParam = () => import("src/pages/group/SortGroupParam.vue");
const EditGroup = () => import("src/pages/group/EditGroup.vue");
const EditAssign = () => import("src/pages/group/EditAssign.vue");
const EditAssignManager = () => import("src/pages/group/EditAssignManager.vue");
const DetailGroup = () => import("src/pages/group/DetailGroup.vue");
const NewGroupManager = () => import("src/pages/group/NewGroupManager.vue");
const GroupListManager = () => import("src/pages/group/GroupListManager.vue");
const GroupListDesigner = () => import("src/pages/group/GroupListDesigner.vue");
const GroupListEditor = () => import("src/pages/group/GroupListEditor.vue");
const GroupListAuthor = () => import("src/pages/group/GroupListAuthor.vue");
const EditGroupManager = () => import("src/pages/group/EditGroupManager.vue");
const PendingActionManager = () =>
  import("src/pages/group/PendingActionManager.vue");
const ChangeGroupLicense = () =>
  import("src/pages/group/ChangeGroupLicense.vue");

// Content Management
const ContentList = () => import("src/pages/content/ContentList.vue");
const NewContent = () => import("src/pages/content/ContentNew.vue");
const EditContent = () => import("src/pages/content/EditContent.vue");
const ModerateContent = () => import("src/pages/content/ModerateContent.vue");
const MultipleNewContent = () =>
  import("src/pages/content/MultipleNewContent.vue");
const SortContent = () => import("src/pages/content/SortContent.vue");

// Template Management
const TemplateList = () => import("src/pages/template/TemplateList.vue");
const NewTemplate = () => import("src/pages/template/NewTemplate.vue");
const NewDesign = () => import("src/pages/template/NewDesign.vue");
const EditDesign = () => import("src/pages/template/EditDesign.vue");
const EditTemplate = () => import("src/pages/template/EditTemplate.vue");
const DesignList = () => import("src/pages/template/DesignList.vue");
const ImportTemplate = () => import("src/pages/template/ImportTemplate.vue");
const SortTemplate = () => import("src/pages/template/SortTemplate.vue");

// Log Management
const UserAction = () => import("src/pages/log/UserAction.vue");

// Data Implimentation Management
const Dataimplimentation = () =>
  import("src/pages/dataimplimentation/dataimplimentation.vue");

// Home Automation Management
const HomeAutomation = () => import("src/pages/home/homeautomation.vue");

// Video Survillance Management
const VideoSurvillance = () => import("src/pages/video/VideoSurvillance.vue");

// User Profile
const Profile = () => import("src/pages/userProfile/Profile.vue");
const Setting = () => import("src/pages/userProfile/Setting.vue");

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
    },
    {
      path: "rtl",
      name: "RTL Page",
      components: { default: RTL },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest },
    },
    {
      path: "/password/reset",
      name: "Password Reset",
      component: PasswordReset,
      meta: { middleware: guest },
    },
    {
      path: "/password/email",
      name: "Password Reset",
      component: PasswordEmail,
      meta: { middleware: guest },
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
    {
      path: "/thankyou",
      name: "Thank you",
      component: Thankyou,
    },
  ],
};

let examplesMenu = {
  path: "/examples",
  component: DashboardLayout,
  name: "Examples",
  children: [
    {
      path: "create-new-param",
      name: "Create New Parameter",
      components: { default: UserProfile },
      meta: { middleware: auth },
    },
    {
      path: "role-management/add-role",
      name: "Add Role",
      components: { default: AddRolePage },
      meta: { middleware: auth },
    },
    {
      path: "paramlist/edit-role/:id",
      name: "Edit Role",
      components: { default: EditRolePage },
      meta: { middleware: auth },
    },
    {
      path: "/user-management/list-users",
      name: "List Users",
      components: { default: ListUserPage },
      meta: { middleware: auth },
    },
    {
      path: "/user-management/list-users-manager",
      name: "List Users Manager",
      components: { default: ListUserManagerPage },
      meta: { middleware: auth },
    },
    {
      path: "/user-management/add-user",
      name: "Add User",
      components: { default: AddUserPage },
      meta: { middleware: auth },
    },
    {
      path: "/user-management/add-user-manager",
      name: "Add User Manager",
      components: { default: AddUserManagerPage },
      meta: { middleware: auth },
    },
    {
      path: "/user-management/edit-user/:id",
      name: "Edit User",
      components: { default: EditUserPage },
      meta: { middleware: auth },
    },
    {
      path: "/user-management/edit-user-manager/:id",
      name: "Edit User Manager",
      components: { default: EditUserManagerPage },
      meta: { middleware: auth },
    },
    {
      path: "category-management/list-categories",
      name: "List Categories",
      components: { default: ListCategoryPage },
      meta: { middleware: admin_creator },
    },
    {
      path: "category-management/add-category",
      name: "Add Category",
      components: { default: AddCategoryPage },
      meta: { middleware: admin_creator },
    },
    {
      path: "category-management/edit-category/:id",
      name: "Edit Category",
      components: { default: EditCategoryPage },
      meta: { middleware: admin_creator },
    },
    {
      path: "/paramlists/assign_default_params",
      name: "Assign parameter",
      components: { default: ListTagPage },
      meta: { middleware: auth },
    },
    {
      path: "/paramlists/add-tag",
      name: "Add Tag",
      components: { default: AddTagPage },
      meta: { middleware: auth },
    },
    {
      path: "/paramlists/new-parameter",
      name: "New Parameter",
      components: { default: NewParameter },
      meta: { middleware: auth },
    },
    {
      path: "/paramlists/research-params",
      name: "List Parameter",
      components: { default: ListParameter },
      meta: { middleware: auth },
    },
    {
      path: "/paramlists/edit-tag/:id",
      name: "Edit Tag",
      components: { default: EditTagPage },
      meta: { middleware: auth },
    },
    {
      path: "/log/user_action",
      name: "User Action",
      components: { default: ListItemPage },
      meta: { middleware: auth },
    },
    {
      path: "/log/add-item",
      name: "Add Item",
      components: { default: AddItemPage },
      meta: { middleware: auth },
    },
    {
      path: "/log/edit-item/:id",
      name: "Edit Item",
      components: { default: EditItemPage },
      meta: { middleware: auth },
    },
    {
      path: "/display/list-display",
      name: "Display List",
      components: { default: DisplayList },
      meta: { middleware: auth },
    },
    // {
    //   path: "/display/list-display-author",
    //   name: "Display List Author",
    //   components: { default: Displaylistauthor },
    //   meta: { middleware: auth },
    // },
    {
      path: "/display/list-display-controller",
      name: "Display List Controllr",
      components: { default: Displaylistcontroller },
      meta: { middleware: auth },
    },
    {
      path: "/display/list-display-watcher",
      name: "Display List Watcher",
      components: { default: Displaylistwatcher },
      meta: { middleware: auth },
    },
    {
      path: "/display/list-display-manager",
      name: "Display List Manager",
      components: { default: DisplayListManager },
      meta: { middleware: auth },
    },
    {
      path: "/display/list-display-other",
      name: "Display List Designer",
      components: { default: DisplayListOther },
      meta: { middleware: auth },
    },
    {
      path: "/display/associate-display/:id",
      name: "Display Associate",
      components: { default: AssociateDisplay },
      meta: { middleware: auth },
    },
    {
      path: "/display/new-display",
      name: "New Display",
      components: { default: NewDisplay },
      meta: { middleware: auth },
    },
    {
      path: "/display/sort-display",
      name: "Sort Display",
      components: { default: SortDisplay },
      meta: { middleware: auth },
    },
    {
      path: "/display/edit-display/:id",
      name: "Edit Display",
      components: { default: EditDisplay },
      meta: { middleware: auth },
    },
    {
      path: "/display/display-template/:id",
      name: "Display Template",
      components: { default: DisplayTemplate },
      meta: { middleware: auth },
    },
    {
      path: "/display/edit-display-manager/:id",
      name: "Edit Display Manager",
      components: { default: EditDisplayManager },
      meta: { middleware: auth },
    },
    {
      path: "/display/detail-display/:id",
      name: "Detail Display",
      components: { default: DetailDisplay },
      meta: { middleware: auth },
    },
    {
      path: "/display/detail-display/:id",
      name: "Detail Display Other",
      components: { default: DetailDisplayOther },
      meta: { middleware: auth },
    },
    {
      path: "/display/detail-display-admin/:id",
      name: "Detail Display Admin",
      components: { default: DetailDisplayAdmin },
      meta: { middleware: auth },
    },
    {
      path: "/display/detail-display-designer/:id",
      name: "Detail Display Designer",
      components: { default: DetailDisplayDesigner },
      meta: { middleware: auth },
    },
    {
      path: "/display/detail-display-author/:id",
      name: "Detail Display Author",
      components: { default: Displaydetailauthor },
      meta: { middleware: auth },
    },
    {
      path: "/display/detail-display-controller/:id",
      name: "Detail Display Controller",
      components: { default: Displaydetailcontroller },
      meta: { middleware: auth },
    },
    {
      path: "/display/detail-display-watcher/:id",
      name: "Detail Display Watcher",
      components: { default: Displaydetailwatcher },
      meta: { middleware: auth },
    },

    {
      path: "/group/list-group",
      name: "Group List",
      components: { default: GroupList },
      meta: { middleware: auth },
    },
    {
      path: "/group/new-group",
      name: "New Group",
      components: { default: NewGroup },
      meta: { middleware: auth },
    },
    {
      path: "/group/sort-group",
      name: "Sort Group",
      components: { default: SortGroupParam },
      meta: { middleware: auth },
    },
    {
      path: "/group/assign-group",
      name: "Assign Group",
      components: { default: AssignGroup },
      meta: { middleware: auth },
    },
    {
      path: "/group/assign-group-manager",
      name: "Assign Group Manager",
      components: { default: AssignGroupManager },
      meta: { middleware: auth },
    },
    {
      path: "/group/pending-action",
      name: "Pending Actions",
      components: { default: PendingAction },
      meta: { middleware: auth },
    },
    {
      path: "/group/edit-group/:id",
      name: "Edit Group",
      components: { default: EditGroup },
      meta: { middleware: auth },
    },
    {
      path: "/group/edit-assign/:id",
      name: "Edit Assign Group",
      components: { default: EditAssign },
      meta: { middleware: auth },
    },
    {
      path: "/group/edit-assign-manager/:id",
      name: "Edit Assign Manager Group",
      components: { default: EditAssignManager },
      meta: { middleware: auth },
    },
    {
      path: "/group/detail-group/:id",
      name: "Group Detail",
      components: { default: DetailGroup },
      meta: { middleware: auth },
    },
    {
      path: "/group/new-group-manager",
      name: "New Group Manager",
      components: { default: NewGroupManager },
      meta: { middleware: auth },
    },
    {
      path: "/group/list-group-manager",
      name: "List Group Manager",
      components: { default: GroupListManager },
      meta: { middleware: auth },
    },
    {
      path: "/group/list-group-designer",
      name: "List Group Designer",
      components: { default: GroupListDesigner },
      meta: { middleware: auth },
    },
    {
      path: "/group/list-group-editor",
      name: "List Group Editor",
      components: { default: GroupListEditor },
      meta: { middleware: auth },
    },
    {
      path: "/group/list-group-author",
      name: "List Group Author",
      components: { default: GroupListAuthor },
      meta: { middleware: auth },
    },
    {
      path: "/group/edit-groupmanager/:id",
      name: "Edit Group Manager",
      components: { default: EditGroupManager },
      meta: { middleware: auth },
    },
    {
      path: "/group/pending-action-manager",
      name: "Pending Action Manager",
      components: { default: PendingActionManager },
      meta: { middleware: auth },
    },
    {
      path: "/user-management/sort-user-param",
      name: "Sort User",
      components: { default: SortUserParam },
      meta: { middleware: auth },
    },
    {
      path: "/license/new-license",
      name: "New License",
      components: { default: NewLicense },
      meta: { middleware: auth },
    },
    {
      path: "/license/list-license",
      name: "License List",
      components: { default: ListLicense },
      meta: { middleware: auth },
    },
    {
      path: "/license/license-expiration",
      name: "License Expiration",
      components: { default: LicenseExpiration },
      meta: { middleware: auth },
    },
    // {
    //   path: "/license/edit-license/:id",
    //   name: "License Detail",
    //   components: { default: LicenseDetail },
    //   meta: { middleware: auth }
    // },
    {
      path: "/license/add-license/:id",
      name: "Add License",
      components: { default: AddLicense },
      meta: { middleware: auth },
    },
    {
      path: "/license/change-license/:id",
      name: "Change License",
      components: { default: ChangeLicense },
      meta: { middleware: auth },
    },
    {
      path: "/groups/change-group-license/:id",
      name: "Change Group License",
      components: { default: ChangeGroupLicense },
      meta: { middleware: auth },
    },
    {
      path: "/license/edit-license/:id",
      name: "Edit License",
      components: { default: EditLicense },
      meta: { middleware: auth },
    },
    {
      path: "/content/list-content",
      name: "List Content",
      components: { default: ContentList },
      meta: { middleware: auth },
    },
    {
      path: "/content/new-content",
      name: "New Content",
      components: { default: NewContent },
      meta: { middleware: auth },
    },
    {
      path: "/content/edit-content/:id",
      name: "Edit Content",
      components: { default: EditContent },
      meta: { middleware: auth },
    },
    {
      path: "/content/moderate-content",
      name: "Moderate Content",
      components: { default: ModerateContent },
      meta: { middleware: auth },
    },
    {
      path: "/content/multiple-new-content",
      name: "Multiple New Content",
      components: { default: MultipleNewContent },
      meta: { middleware: auth },
    },
    {
      path: "/content/sort-content",
      name: "Sort Content",
      components: { default: SortContent },
      meta: { middleware: auth },
    },
    {
      path: "/update/excutable-update",
      name: "Excutable Update",
      components: { default: ExcutableUpdate },
      meta: { middleware: auth },
    },
    {
      path: "/update/package-update",
      name: "Package Update",
      components: { default: PackageUpdate },
      meta: { middleware: auth },
    },
    {
      path: "/template/list-template",
      name: "List Template",
      components: { default: TemplateList },
      meta: { middleware: auth },
    },
    {
      path: "/template/new-template",
      name: "New Template",
      components: { default: NewTemplate },
      meta: { middleware: auth },
    },
    {
      path: "/template/sort-template",
      name: "Sort Template",
      components: { default: SortTemplate },
      meta: { middleware: auth },
    },
    {
      path: "/template/new-design",
      name: "New Design",
      components: { default: NewDesign },
      meta: { middleware: auth },
    },
    {
      path: "/template/edit-design/:id",
      name: "Edit Design",
      components: { default: EditDesign },
      meta: { middleware: auth },
    },
    {
      path: "/template/edit-template/:id",
      name: "Edit Template",
      components: { default: EditTemplate },
      meta: { middleware: auth },
    },
    {
      path: "/template/import-template",
      name: "Import Template",
      components: { default: ImportTemplate },
      meta: { middleware: auth },
    },
    {
      path: "/template/list-design",
      name: "List Design",
      components: { default: DesignList },
      meta: { middleware: auth },
    },
    {
      path: "/statistics/view-statics",
      name: "View Statistics",
      components: { default: ViewStatics },
      meta: { middleware: auth },
    },
    {
      path: "/statistics/web-cam",
      name: "Webcam Statistics",
      components: { default: WebCam },
      meta: { middleware: auth },
    },
    {
      path: "/statistics/wi-fi",
      name: "Wifi Statistics",
      components: { default: WiFi },
      meta: { middleware: auth },
    },
    {
      path: "/playlist/play-list",
      name: "Play List",
      components: { default: Playlist },
      meta: { middleware: auth },
    },
    {
      path: "/playlist/new-playlist",
      name: "New Playlist",
      components: { default: NewPlaylist },
      meta: { middleware: auth },
    },
    {
      path: "/playlist/edit-playlist/:id",
      name: "Edit Playlist",
      components: { default: EditPlaylist },
      meta: { middleware: auth },
    },
    {
      path: "/container/list-container",
      name: "List Container",
      components: { default: ListContainer },
      meta: { middleware: auth },
    },
    {
      path: "/container/edit-container/:id",
      name: "Edit Conatiner",
      components: { default: EditContainer },
      meta: { middleware: auth },
    },
    {
      path: "/container/new-container",
      name: "New Container",
      components: { default: NewContainer },
      meta: { middleware: auth },
    },
    {
      path: "/container/associate-playlist",
      name: "Associate Playlist",
      components: { default: AssociatPlaylist },
      meta: { middleware: auth },
    },
    {
      path: "/container/playlist-associate/:id",
      name: "Playlist Associate",
      components: { default: PlaylistAssociate },
      meta: { middleware: auth },
    },
    {
      path: "/log/user-action",
      name: "User Action",
      components: { default: UserAction },
      meta: { middleware: auth },
    },
    {
      path: "/dataimplimentation/data-implimentation",
      name: "Data Implimentation",
      components: { default: Dataimplimentation },
      meta: { middleware: auth },
    },
    {
      path: "/home/home-automation",
      name: "Home Automation",
      components: { default: HomeAutomation },
      meta: { middleware: auth },
    },
    {
      path: "/video/video-survillance",
      name: "Video Survillance",
      components: { default: VideoSurvillance },
      meta: { middleware: auth },
    },
    {
      path: "/profile",
      name: "My Profile",
      components: { default: Profile },
      meta: { middleware: auth },
    },
    {
      path: "/settings",
      name: "Settings Page",
      components: { default: Setting },
      meta: { middleware: auth },
    }
  ],
};

// let authPages = {
//   path: '/',
//   component: AuthLayout,
//   name: 'Authentication',
//   children: [
//     {
//       path: '/login',
//       name: 'Login',
//       component: Login
//     },
//     {
//       path: '/register',
//       name: 'Register',
//       component: Register
//     },
//     {
//       path: '/pricing',
//       name: 'Pricing',
//       component: Pricing
//     },
//     {
//       path: '/lock',
//       name: 'Lock',
//       component: Lock
//     }
//   ]
// };

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  examplesMenu,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "Dashboard layout",
    meta: { middleware: auth },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: { middleware: auth },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar },
        meta: { middleware: auth },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts },
        meta: { middleware: auth },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets },
        meta: { middleware: auth },
      },
    ],
  },
  // { path: '*', component: NotFound }
];

export default routes;
