import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
const url = process.env.VUE_APP_API_BASE_URL;

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/login",
  registerUrl: "/register",
});

export default {
  state: {
    isAuthenticated:
      localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
  },

  actions: {
    login(context, payload) {
      return vueAuth
        .login(payload.user, payload.requestOptions)
        .then((response) => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          router.push({ name: "Home" });
        });
    },

    register(context, payload) {
      return vueAuth
        .register(payload.user, payload.requestOptions)
        .then((response) => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          router.push({ name: "Home" });
        });
    },

    logout(context, payload) {
      // console.log(context);
      return vueAuth.logout().then((response) => {
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated(),
        });
        router.push({ name: "Login" });
      });
    },

    async newlogin(context, id) {
      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      // console.log('newlogin');
      try {
        const response = await axios.post(
          `${url}/loginnew`,
          id,
          requestOptions
        );
        // console.log(response);
        localStorage.removeItem("vue-authenticate.vueauth_access_token");
        localStorage.setItem(
          "vue-authenticate.vueauth_access_token",
          response.data.access_token
        );

        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated(),
        });
        router.push({ name: "Home" });
      } catch (error) {}
    },
  },
};
