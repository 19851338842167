import service from '@/store/services/displays-service';

const state = {
  list: {},
  display: {},
  meta: {},
  display_list: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_DISPLAY_LIST: (state, display_list) => {
    state.display_list = display_list;
  },
  SET_RESOURCE: (state, display) => {
    state.display = display;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
    .then((display_list) => {
      commit('SET_DISPLAY_LIST', display_list);
    });
  },


    displaygetdata({commit, dispatch}, params) {
    return service.displaygetdata(params)
      .then((display_list) => {
        commit('SET_DISPLAY_LIST', display_list);
      });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
    .then((display_list) => {
      commit('SET_DISPLAY_LIST', display_list);
    });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((display) => { commit('SET_RESOURCE', display); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  del({commit, dispatch}, params) {
    return service.del(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  display_list: state => state.display_list,
  display: state => state.display,
  dropdown: (state) => {
    return state.list.map(category => ({
      id: display.id,
    
    }));
  }
};

const displays = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default displays;
