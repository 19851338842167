<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar navbarback"
    type="white"
    :transparent="true"
    style="position: fixed"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <!-- <sidebar-toggle-button /> -->
      </div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a href="/dashboard"
        ><img src="/img/zendslogo.png" height="35px" width="35px"
      /></a>

      <!-- <a class="navbar-brand" href="javascript::void()" style="font-size:30px"> {{ routeName }}</a> -->
      <a class="navbar-brand" href="javascript::void()" style="font-size: 30px">
        <b class="bold" style="font-weight: 600">ZEN</b>DS
      </a>
    </div>
    <!-- <div style="margin-left: 80px; margin-top: 10px">
      <a href="/dashboard"
        ><base-button type="primary" round icon>
          <i class="fa fa-home" aria-hidden="true"></i> </base-button
      ></a>
      <sidebar-toggle-button />
    </div> -->
    <div class="ml-auto">
      <!-- <a href="/login"><span slot="header" class="badge badge-info">Return To admin</span></a> || -->
      <!-- <span slot="header" class="badge badge-info">{{ title }}</span> -->
    </div>
    <!-- <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : ''">
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template slot="title">
          <div
            class="photo avatar-image"
            :style="{ 'background-image': `url('${profileImage}')` }"
          />
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">{{$t("Log out")}}</p>
        </template>

        <li class="nav-link">
          <a @click="logout" class="nav-item dropdown-item">{{$t("Log out")}}</a>
        </li>
      </base-dropdown>
    </ul> -->
    <!-- </div>  -->
  </base-nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { BaseNav, Modal } from "@/components";
import SidebarToggleButton from "./SidebarToggleButton";

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    profileImage() {
      return this.avatar ? this.avatar : "/img/placeholder.jpg";
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      avatar: null,
      title: null,
    };
  },
  async created() {
    localStorage.role_id = 0;
    this.$store.watch(
      () => this.$store.getters["profile/me"],
      (me) => {
        this.title = me.name;
        this.avatar = me.profile_image;
        localStorage.role_id = me.roles[0].id;
      }
    );
    try {
      await this.$store.dispatch("profile/me");
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push("/login");
        localStorage.removeItem("vue-authenticate.vueauth_access_token");
        this.$store.commit("isAuthenticated", { isAuthenticated: false });
      }
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.dispatch("logout");
      localStorage.removeItem("activeAccordion");
      localStorage.removeItem("childActiveAccordionPath");
    },
    goToProfile() {
      this.$router.push({ name: "List Users" });
    },
  },
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}

.navbarback {
  background-color: white !important;
}

.navbar {
  padding: 0;
}

.bold {
  font-family: "Roboto", sans-serif;
}
</style>
