import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list() {
    let options = {
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
    };
  
    return axios.get(`${url}/resolutions`, options)
      .then(response => {
        return {
          list: jsona.deserialize(response.data),
          meta: response.data.meta
        };
      });
  }

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/resolutions/${id}`, options)
    .then(response => {
      let resolution = jsona.deserialize(response.data);
      delete resolution.links;
      return resolution;
    });
}

function add(resolution) {
  const payload = jsona.serialize({
    stuff: resolution,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
 
  return axios.post(`${url}/resolutions`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(resolution) {
  const payload = jsona.serialize({
    stuff: resolution,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/resolution/${resolution.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/resolutions/${id}`, options);
}

function categorywrapper() {
  return axios.post(`${url}/categorywrapper`)
    .then(response => {
      return response.data;
    });
}

function returnsectionscommandwrapper() {
  return axios.post(`${url}/returnsectionscommandwrapper`)
    .then(response => {
      return response.data;
    });
}
function returntemplatewrapper() {
  return axios.post(`${url}/returntemplatewrapper`)
    .then(response => {
      return response.data;
    });
}
function insertdesignedtemplatewrapper(dataBoxArray) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/insertdesignedtemplatewrapper`, dataBoxArray, options)
    .then(response => {
      return response.data;
    });
}
function updatedesignedtemplatewrapper(dataBoxArray) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  // console.log(dataBoxArray.design_id);
  return axios.post(`${url}/updatedesignedtemplatewrapper/${dataBoxArray.design_id}`, dataBoxArray, options)
    .then(response => {
      return response.data;
    });
}
function getdesignlist() {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/get-design-list`, options)
    .then(response => {
      return response.data;
    });
}


export default {
  list,
  get,
  add,
  update,
  destroy,
  categorywrapper,
  returnsectionscommandwrapper,
  returntemplatewrapper,
  insertdesignedtemplatewrapper,
  getdesignlist,
  updatedesignedtemplatewrapper,
};

