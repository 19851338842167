import service from '@/store/services/templatedetails-service';

const state = {
  list: {},
  resolution: {},
  meta: {},
  cat_wrapper: {},
  template_wrapper: {},
  designed_template_wrapper: {},
  command_wrapper: {},
  playlist: {},
  design_list: {},
  update_designed_template_wrapper: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, resolution) => {
    state.resolution = resolution;
  },
  CATEGORY_WRAPPER: (state, cat_wrapper) => {
    state.cat_wrapper = cat_wrapper;
  },
  TEMPLATE_WRAPPER: (state, template_wrapper) => {
    state.template_wrapper = template_wrapper;
  },
  COMMAND_WRAPPER: (state, command_wrapper) => {
    state.command_wrapper = command_wrapper;
  },
  DESIGNED_TEMPLATE_WRAPPER: (state, designed_template_wrapper) => {
    state.designed_template_wrapper = designed_template_wrapper;
  },
  UPDATE_DESIGNED_TEMPLATE_WRAPPER: (state, update_designed_template_wrapper) => {
    state.update_designed_template_wrapper = update_designed_template_wrapper;
  },
  PLAYLIST: (state, playlist) => {
    state.playlist = playlist;
  },
  DESIGN_LIST: (state, design_list) => {
    state.design_list = design_list;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params)
      .then(({ list, meta }) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({ commit, dispatch }, params) {
    return service.get(params)
      .then((resolution) => { commit('SET_RESOURCE', resolution); });
  },

  add({ commit, dispatch }, params) {
    return service.add(params)
      .then((resolution) => { commit('SET_RESOURCE', resolution); });
  },

  update({ commit, dispatch }, params) {
    return service.update(params)
      .then((resolution) => { commit('SET_RESOURCE', resolution); });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
  categorywrapper({ commit, dispatch }, params) {
    return service.categorywrapper(params)
      .then((cat_wrapper) => { commit('CATEGORY_WRAPPER', cat_wrapper); });
  },
  returnsectionscommandwrapper({ commit, dispatch }, params) {
    return service.returnsectionscommandwrapper(params)
      .then((cat_wrapper) => { commit('COMMAND_WRAPPER', cat_wrapper); });
  },
  returntemplatewrapper({ commit, dispatch }, params) {
    return service.returntemplatewrapper(params)
      .then((template_wrapper) => { commit('TEMPLATE_WRAPPER', template_wrapper); });
  },
  insertdesignedtemplatewrapper({ commit, dispatch }, params) {
    return service.insertdesignedtemplatewrapper(params)
      .then((designed_template_wrapper) => { commit('DESIGNED_TEMPLATE_WRAPPER', designed_template_wrapper); });
  },
  updatedesignedtemplatewrapper({ commit, dispatch }, params) {
    return service.updatedesignedtemplatewrapper(params)
      .then((update_designed_template_wrapper) => { commit('UPDATE_DESIGNED_TEMPLATE_WRAPPER', update_designed_template_wrapper); });
  },
  getdesignlist({ commit, dispatch }) {
    return service.getdesignlist()
      .then((design_list) => { commit('DESIGN_LIST', design_list); });
  },

};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  resolution: state => state.resolution,
  cat_wrapper: state => state.cat_wrapper,
  command_wrapper: state => state.command_wrapper,
  template_wrapper: state => state.template_wrapper,
  designed_template_wrapper: state => state.designed_template_wrapper,
  update_designed_template_wrapper: state => state.update_designed_template_wrapper,
  playlist: state => state.playlist,
  dropdown: (state) => {
    return state.list.map(resolution => ({
      id: resolution.id,
      name: resolution.name
    }));
  },
  design_list: state => state.design_list
};

const templatesdetail = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default templatesdetail;
