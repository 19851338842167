import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/displays`, options)  
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}


function get(params) {
 
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/displaygetdatamanager/${JSON.stringify(params)}`)  
    .then(response => {
      return response.data;
    });
}


function displaygetdata(params) {
  
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/displaygetdata/${JSON.stringify(params)}`)  
    .then(response => {
      return response.data;
    });
}


function add(params) {
    
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/displaygetdataadmin/${JSON.stringify(params)}`)  
    .then(response => {
      return response.data;
    });
}

function update(display) {
  const payload = jsona.serialize({
    stuff: display,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/displays/${display.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  return axios.delete(`${url}/displaydelete/${id}`, options);
}

function del(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/deletecontrol/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  del,
  displaygetdata
};

