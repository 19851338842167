<template>
  <footer
    class="footer-distributed footercolor"
    :class="$store.getters['sidebarToggle/getToggleKey'] ? 'footerSection' : ''"
  >
    <div
      class="p-20 d-flex justify-content-between align-items-center text-center f-section"
      style="
        padding: 20px 30px 20px 18px;
        font-size: 15px !important;
        font-weight: 600;
      "
    >
      <!-- <img
        width="270"
        height="54"
        src="https://www.zenyth.net/wp-content/uploads/2022/03/Zenyth-logo_Bianco.png"
        class="attachment-full size-full"
        alt=""
        loading="lazy"
        srcset="
          https://www.zenyth.net/wp-content/uploads/2022/03/Zenyth-logo_Bianco.png        524w,
          https://www.zenyth.net/wp-content/uploads/2022/03/Zenyth-logo_Bianco-300x56.png 300w
        "
        sizes="(max-width: 524px) 100vw, 524px"
      /> -->

      <p class="footer-company-name mobile-section" style="color: var(--gray)">
        <!-- {{
          $t("Zenyth Srl © 2013-2023&nbsp;&nbsp;&nbsp;VAT &nbsp; IT08788210964")
        }} -->
        Zenyth Srl © 2013-2023&nbsp;&nbsp;&nbsp;VAT &nbsp; IT08788210964
      </p>
      <p
        class="footer-links text-center d-flex justify-content-around mobile-section"
        style="gap: 20px"
      >
        <a
          href="/EULA Zends.eu V.9.pdf"
          style="color: var(--gray); font-weight: 600"
          target="_blank"
          >{{ $t("EULA") }}
        </a>
        <a href="#" style="color: var(--gray); font-weight: 600"
          >{{ $t("Zenyth") }}
        </a>
        <a href="#" style="color: var(--gray); font-weight: 600"
          >{{ $t("Swedx") }}
        </a>
        <a href="#" style="color: var(--gray); font-weight: 600"
          >{{ $t("Help") }}
        </a>
        <a href="#" style="color: var(--gray); font-weight: 600"
          >{{ $t("Treezen") }}
        </a>
      </p>
      <!-- <p class="footer-links text-center" style="font-size: 15px !important">
        <a
          href="mailto:assistenza@zenyth.net"
          style="color: white"
          class="fa fa-envelope"
        ></a>
        &nbsp; · &nbsp;
        <a
          href="https://facebook.com/zenyth.net"
          style="color: white"
          class="fa fa-facebook"
          target="_blank"
        ></a>
        &nbsp; · &nbsp;
        <a
          href="https://www.linkedin.com/company/zenyth/"
          target="_blank"
          style="color: white"
          class="fa fa-linkedin"
        ></a>
        &nbsp; · &nbsp;
        <a
          href="https://www.youtube.com/channel/UC7LWgO092KuNZNZi6aKVGag"
          style="color: white"
          class="fa fa-youtube"
          target="_blank"
        ></a>
      </p> -->
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
footer {
  padding: 0 !important;
}
.footer-distributed {
  border-radius: 10px;
  margin-bottom: 8px;
  width: calc(100vw - 16.4rem);
  float: right;
  transition: all 0.1s ease-in-out;
}

.footerSection {
  width: calc(100vw - 7rem);
  float: right;
}

@media (max-width: 1200px) {
  .footer-distributed {
    width: calc(100vw - 1.2rem);
    margin-left: 0;
  }
}

@media (max-width: 680px) {
  .f-section {
    flex-direction: column-reverse !important;
  }

  .mobile-section {
    margin-bottom: 10px;
  }
}
</style>
