/*!

 =========================================================
 * Zends - v1.1.0
 =========================================================

 * Product Page: http://zends.it
 * Copyright Zends Tim (http://zends.it)

 =========================================================

 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';


// router setup
import router from './router';
import store from "./store";
import i18n from './i18n';
import './registerServiceWorker';


import VueMeta from 'vue-meta'
import IsDemo from './isDemo'
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(IsDemo)
Vue.use(VueMeta, { keyName: 'head' });



Vue.prototype.store = 
  {
    "Administrator" : 100,
    "Manger" : 100
  }



/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router: router,
  store: store,
  i18n
});
