<template>
  <!-- <el-tooltip content="Sidebar toggle" effect="light" :open-delay="300" placement="right"> -->
  <!-- <button class="minimize-sidebar btn btn-link btn-just-icon" @click="minimizeSidebar" rel="tooltip" data-original-title="Sidebar toggle" data-placement="right">
        <i class="tim-icons icon-align-center visible-on-sidebar-regular"></i>
        <i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"></i>
    </button> -->

  <base-button
    type="primary"
    @click="minimizeSidebar"
    rel="tooltip"
    data-original-title="Sidebar toggle"
    data-placement="right"
    round
    icon
  >
    <i class="tim-icons icon-align-center visible-on-sidebar-regular"></i>
    <i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"></i>
  </base-button>
  <!-- </el-tooltip> -->
</template>

<script>
export default {
  name: "sidebar-toggle-button",
  methods: {
    minimizeSidebar() {
      localStorage.sidebaractive = !this.$sidebar.isMinimized;
      this.$store.dispatch(
        "sidebarToggle/updateToggleKey",
        !this.$sidebar.isMinimized
      );

      // Remove this code if you don't want to display notifications while minimizing sidebar
      let isMinimizedText = this.$sidebar.isMinimized
        ? "deactivated"
        : "activated";
      this.$notify({
        type: "primary",
        message: this.$t(`Sidebar mini ${isMinimizedText}...`),
        icon: "tim-icons icon-bell-55",
      });
      this.$sidebar.toggleMinimize();
    },
  },
  created() {
    if (localStorage.sidebaractive) {
      this.$sidebar.toggleMinimize();
    } else {
      localStorage.sidebaractive = this.$sidebar.isMinimized;
      this.$store.dispatch("sidebarToggle/updateToggleKey", true);
    }
  },
};
</script>

<style></style>
