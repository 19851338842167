import service from '@/store/services/countrymaps-service.js';
import countrymapsService from '../services/countrymaps-service';


const state = {
  list: {},
  license: {},
  meta: {},
  countrymapsdata: {},
  message: null
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, countrymapsdata) => {
    state.countrymapsdata = countrymapsdata;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  MESSAGE: (state, message) => {
    state.message = message;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  changemap({commit, dispatch}, params) {
    return service.changemap(params)
      .then((countrymapsdata) => { commit('SET_RESOURCE', countrymapsdata); });
  },
  getmapcountry({commit, dispatch}, params) {
    return service.getmapcountry(params)
      .then((countrymapsdata) => { commit('SET_RESOURCE', countrymapsdata); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((countrymapsdata) => { commit('SET_RESOURCE', countrymapsdata); });
  },

  addexecutable({commit, dispatch}, params) {
    return service.addexecutable(params)
      .then((countrymapsdata) => { commit('SET_RESOURCE', countrymapsdata); });
  },
  
  update({commit, dispatch}, params) {
    return service.update(params)
    .then((countrymapsdata) => { commit('SET_RESOURCE', countrymapsdata); });
  },
  
  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, params) {
    return service.upload(params)
      .then((message) => {  commit('MESSAGE', message); });
  },
  uploadexe({commit, dispatch}, params) {
    return service.uploadexe(params)
      .then((message) => {  commit('MESSAGE', message); });
  },
};
const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  license: state => state.license,
  message: state => state.message,
  countrymapsdata: state => state.countrymapsdata,
};

const countrymaps = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default countrymaps;
