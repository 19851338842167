import service from '@/store/services/contentfile-service';


const state = {
  list: {},
  license: {},
  meta: {},
  content_list:{},
  updatepack: {},
  message: null
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, updatepack) => {
    state.updatepack = updatepack;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_CONTENT_LIST: (state, content_list) => {
    state.content_list = content_list;
  },
  MESSAGE: (state, message) => {
    state.message = message;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  contentgetdata({commit, dispatch}, params) {
    return service.contentgetdata(params)
      .then((content_list) => {
        commit('SET_CONTENT_LIST', content_list);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((updatepack) => { commit('SET_RESOURCE', updatepack); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((updatepack) => { commit('SET_RESOURCE', updatepack); });
  },

  addexecutable({commit, dispatch}, params) {
    return service.addexecutable(params)
      .then((updatepack) => { commit('SET_RESOURCE', updatepack); });
  },
  
  update({commit, dispatch}, params) {
    return service.update(params)
    .then((updatepack) => { commit('SET_RESOURCE', updatepack); });
  },
  
  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, params) {
    return service.upload(params)   
      .then((message) => {  commit('MESSAGE', message); });
  },

  uploadfile({commit, dispatch}, params) {
    return service.uploadfile(params)   
      .then((message) => {  commit('MESSAGE', message); });
  },
  uploadsingle({commit, dispatch}, params) {
    return service.uploadsingle(params)   
      .then((message) => {  commit('MESSAGE', message); });
  },
  uploadmulti({commit, dispatch}, params) {
    return service.uploadmulti(params)   
      .then((message) => {  commit('MESSAGE', message); });
  },
  uploadexe({commit, dispatch}, params) {
    return service.uploadexe(params)
      .then((message) => {  commit('MESSAGE', message); });
  },
};
const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  license: state => state.license,
  message: state => state.message,
  updatepack: state => state.updatepack,
  content_list: state => state.content_list,
};

const updatepackage = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default updatepackage;
