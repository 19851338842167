var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title'),"show-one-children":true},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_vm._l((_vm.sidebarData),function(items,i){return [(_vm.roles.includes(items.user))?_c('div',{key:i},[_vm._l((items.data),function(item,index){return [_c('li',{key:index,ref:"sidebarMenuItem",refInFor:true,staticClass:"sidebar-menu-item",on:{"click":function($event){return _vm.toggleAccordion(index, $event)}}},[(item && item.children && item.children.length !== 0)?_c('div',{staticClass:"main-menu-item",class:_vm.isClickedParentItem === index ? 'clicked-menu-item' : ''},[(item.name === 'loginuser')?[_c('img',{staticClass:"user-image",attrs:{"src":_vm.profileImage}}),_c('p',{staticClass:"parent-item",class:_vm.$store.getters['sidebarToggle/getToggleKey']
                        ? 'parent-collapsed-item'
                        : ''},[_vm._v(" "+_vm._s(_vm.title)),_c('b',{staticClass:"caret"})])]:[_c('i',{class:item.icon}),_c('p',{staticClass:"parent-item",class:_vm.$store.getters['sidebarToggle/getToggleKey']
                        ? 'parent-collapsed-item'
                        : ''},[_vm._v(" "+_vm._s(_vm.$t(item.name))),_c('b',{staticClass:"caret"})])]],2):_c('div',{staticClass:"main-menu-item",class:_vm.isClickedParentItem === index
                    ? 'clicked-child-menu-item'
                    : ''},[_c('router-link',{attrs:{"to":item.path}},[_c('i',{class:item.icon}),_c('p',{staticClass:"parent-item",class:_vm.$store.getters['sidebarToggle/getToggleKey']
                        ? 'parent-collapsed-item'
                        : ''},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])])],1),_c('collapse-transition',[(_vm.activeAccordion === index)?_c('div',[_vm._l((item.children),function(childItem,childIndex){return [_c('div',{key:childIndex,on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.handleClickedChildItem(childItem, childIndex)}]}},[(childItem.path === '/logout')?_c('a',{staticClass:"sidebar-normal",class:childItem.path === _vm.isClickedChildItem
                            ? 'clicked-child-menu-item'
                            : '',on:{"click":function($event){$event.preventDefault();return _vm.logoutButton.apply(null, arguments)}}},[_c('span',{staticClass:"sidebar-mini-icon",class:_vm.$store.getters['sidebarToggle/getToggleKey']
                              ? 'child-mini-icon'
                              : ''},[_vm._v(_vm._s(_vm.getInitials(_vm.$t(childItem.name))))]),_c('span',{staticClass:"mini-item",class:_vm.$store.getters['sidebarToggle/getToggleKey']
                              ? 'child-collapsed-item'
                              : ''},[_vm._v(_vm._s(_vm.$t(childItem.name)))])]):_c('router-link',{staticClass:"sidebar-normal",class:childItem.path === _vm.isClickedChildItem
                            ? 'clicked-child-menu-item'
                            : '',attrs:{"to":childItem.path}},[_c('span',{staticClass:"sidebar-mini-icon",class:_vm.$store.getters['sidebarToggle/getToggleKey']
                              ? 'child-mini-icon'
                              : ''},[_vm._v(_vm._s(_vm.getInitials(_vm.$t(childItem.name))))]),_c('span',{staticClass:"mini-item",class:_vm.$store.getters['sidebarToggle/getToggleKey']
                              ? 'child-collapsed-item'
                              : ''},[_vm._v(_vm._s(_vm.$t(childItem.name)))])])],1)]})],2):_vm._e()])],1)]})],2):_vm._e()]})]}}])}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer',[_vm._v("66")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }