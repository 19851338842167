import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/groups`, options)  
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/groupedit/${id}`, options)
    .then(response => {
      let group = jsona.deserialize(response.data);
      delete group.links;
      return group;
    });
}

function add(group) {
  const payload = jsona.serialize({
    stuff: group,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
 
  return axios.post(`${url}/addgroup`, payload, options)
    .then(response => {
     
      return jsona.deserialize(response.data);
    });
}

function groupgetdata(params) {
  
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/groupgetdata/${JSON.stringify(params)}`)  
    .then(response => {
      return response.data;
    });
}




function associate(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/associateUser/${JSON.stringify(params)}`)  
    .then(response => {
      return response.data;
    });
}

function update(group) {
  const payload = jsona.serialize({
    stuff: group,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/groups/${group.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  // console.log(id);
  return axios.delete(`${url}/groupdelete/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  groupgetdata,
  associate,
};

