import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import categories from "./modules/categories-module";
import paramlists from './modules/paramlists-module';
import displays from './modules/displays-module';
import tags from "./modules/tags-module";
import items from "./modules/items-module";
import reset from "./modules/reset";
import groups from "./modules/groups-module";
import groupmanagers from "./modules/groupmanagers-module";
import licenses from "./modules/licenses-module";
import storeorderparams from "./modules/storeorderparams-module";
import updatepackage from "./modules/updatepackage-module";
import contentfile from "./modules/contentfile-module";
import associates from "./modules/associates-module";
import templatesdetail from "./modules/templatedetails-module";
import countrymaps from "./modules/countrymaps-modules";
import playlists from "./modules/playlists-module";
import designs from "./modules/designs-module";
import sidebarToggle from "./modules/sidebartoggle-module";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    roles,
    categories,
    paramlists,
    displays,
    tags,
    items,
    reset,
    groups,
    groupmanagers,
    licenses,
    storeorderparams,
    updatepackage,
    associates,
    templatesdetail,
    contentfile,
    countrymaps,
    playlists,
    designs,
    sidebarToggle
  }
});
