import qs from "qs";
import axios from "axios";
import Jsona from "jsona";
import { requote } from "d3";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/categories`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}
function add(updatepack) {
  const payload = jsona.serialize({
    stuff: updatepack,
    includeNames: null,
  });
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/groupadd/package`, payload, options)
    .then((response) => {
      return response.data;
    });
}

function contentgetdata(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios
    .get(`${url}/getcontentdata/${JSON.stringify(params)}`)
    .then((response) => {
      return response.data;
    });
}

function addexecutable() {
  return axios.post(`${url}/playerall/executable`).then((response) => {
    return response.data;
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/categories/${id}`, options).then((response) => {
    let category = jsona.deserialize(response.data);
    delete category.links;
    return category;
  });
}

function update(category) {
  const payload = jsona.serialize({
    stuff: category,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/categories/${category.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/categories/${id}`, options);
}

function upload(file) {
  const formData = new FormData();
  formData.append("filename", file["filename"]);
  formData.append("filesize", file["filesize"]);
  formData.append("filetype", file["filetype"]);
  formData.append("description_1", file.data.Description_1);
  formData.append("name_3", file.data.name_3);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios
    .post(`${url}/addfile`, formData, {
      options,
    })
    .then((response) => {
      return response.data.message;
    });
}

function uploadfile(file) {
  const formData = new FormData();
  formData.append("file", file["file"]);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios
    .post(`${url}/adduploadfile`, formData, {
      options,
      onUploadProgress: function (progressEvent) {
        this.uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }.bind(this),
    })
    .then((response) => {
      return response.data.message;
    });
}

function uploadsingle(file) {
  const formData = new FormData();
  formData.append("file", file["file"]["file"]);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .post(`${url}/addfilesingle`, formData, {
      options,
      onUploadProgress: function (progressEvent) {
        this.uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }.bind(this),
    })
    .then((response) => {
      return response.data.message;
    });
}

function uploadmulti(file) {
  const formData = new FormData();
  formData.append("file", file);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .post(`${url}/addmultifile`, formData, {
      options,
      onUploadProgress: function (progressEvent) {
        this.uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }.bind(this),
    })
    .then((response) => {
      return response.data.message;
    });
}

function uploadexe(file) {
  const formData = new FormData();
  formData.append("file", file);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .post(`${url}/uploads/exefile`, formData, {
      options,
      onUploadProgress: function (progressEvent) {
        this.uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }.bind(this),
    })
    .then((response) => {
      return response.data.message;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  uploadsingle,
  uploadfile,
  uploadexe,
  uploadmulti,
  addexecutable,
  contentgetdata,
};
