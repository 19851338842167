import service from '@/store/services/licenses-service';


const state = {
  list: {},
  license: {},
  meta: {},
  license_list: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, license) => {
    state.license = license;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_LICENSES_LIST: (state, license_list) => {
    state.license_list = license_list;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then((license_list) => {
        commit('SET_LICENSES_LIST', license_list);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((license) => { commit('SET_RESOURCE', license); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((license) => { commit('SET_RESOURCE', license); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((license) => { commit('SET_RESOURCE', license); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  license_list: state => state.license_list,
  listTotal: state => state.meta.page.total,
  license: state => state.license,
  dropdown: (state) => {
    return state.list.map(license  => ({
       id: license.id,
    }));
  }
};

const licenses = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default licenses;
