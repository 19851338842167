const state = {
  toggleKey: false,
};

const mutations = {
  SET_TOGGLE_KEY: (state, toggleKey) => {
    state.toggleKey = toggleKey;
  },
};

const actions = {
  updateToggleKey: ({ commit }, toggleKey) => {
    commit("SET_TOGGLE_KEY", toggleKey);
  },
};

const getters = {
  getToggleKey: (state) => {
    return state.toggleKey;
  },
};

const sidebarToggle = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default sidebarToggle;
