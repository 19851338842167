import service from '@/store/services/playlists-service';

const state = {
  list: {},
  getplaylist: {},
  meta: {},
  url: null
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_PLAYLIST: (state, getplaylist) => {
    state.getplaylist = getplaylist;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then((getplaylist) => {
        commit('SET_PLAYLIST', getplaylist);
      });
  },
};

const getters = {
    list: state => state.getplaylist.data,
    listTotal: state => state.getplaylist.total,
};

const playlists = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default playlists;
