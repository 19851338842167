import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/categories`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}
function add(updatepack) {
  const payload = jsona.serialize({
    stuff: updatepack,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/playerall/package`, payload, options)
    .then(response => {
      return response.data;
    });
}

function addexecutable() {

  return axios.post(`${url}/playerall/executable`)
    .then(response => {
      return response.data;
    });
}

function changemap(id) {

  return axios.post(`${url}/changemap/${id}`)
    .then(response => {
      let category = jsona.deserialize(response.data);
      return category;
    });
}

function getmapcountry() {


  return axios.get(`${url}/getmapcountry`)
    .then(response => {
      
      return response.data;
    });
}



function update(category) {
  const payload = jsona.serialize({
    stuff: category,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/categories/${category.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}



function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/categories/${id}`, options);
}

function upload(file) {
  const formData = new FormData();
  formData.append('file', file);

  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  return axios.post(`${url}/uploads/zipfile`, formData, {
    options,
    onUploadProgress: function (progressEvent) {
      this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    }.bind(this)
  })
    .then(response => {
      return response.data.message;
    });
}

function uploadexe(file) {
  const formData = new FormData();
  formData.append('file', file);

  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  return axios.post(`${url}/uploads/exefile`, formData, {
    options,
    onUploadProgress: function (progressEvent) {
      this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    }.bind(this)
  })
    .then(response => {
      return response.data.message;
    });
}

export default {
  list,
  changemap,
  add,
  update,
  destroy,
  upload,
  uploadexe,
  addexecutable,
  getmapcountry,
};

